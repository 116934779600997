import React, { useEffect, useRef, useState } from "react";
import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import { AIHero } from "@/components/common/ai-hero";
import { useNavigate } from "react-router-dom";
import { jwtDecode } from "jwt-decode";
import { Loader2Icon } from "lucide-react";
import NProgress from "nprogress";
import "nprogress/nprogress.css";

interface PlannedOutage {
  id: string;
  title: string;
  description: string;
  createdAt: string;
  state: {
    name: string;
  };
}

interface Project {
  project_id: string;
  [key: string]: any;
}

interface TokenResponse {
  token: string;
  me: string;
  title?: string;
}

interface ProjectsResponse {
  projects: Project[];
}

const Main = () => {
  const navigate = useNavigate();
  const emailInputRef = useRef<HTMLInputElement>(null);
  const otpInputRef = useRef<HTMLInputElement>(null);

  const [isLoggedIn, setIsLoggedIn] = useState<boolean>(false);
  const [isLoadingProject, setIsLoadingProject] = useState<boolean>(true);
  const [email, setEmail] = useState<string>("");
  const [otp, setOtp] = useState<string>("");
  const [otpState, setOtpState] = useState<boolean>(false);
  const [authError, setAuthError] = useState<string>("");
  const [plannedOutages, setPlannedOutages] = useState<PlannedOutage[]>([]);

  const checkLoggedInStatus = async () => {
    NProgress.start();
    try {
      const token = localStorage.getItem("token");
      const headers = new Headers({
        "Content-Type": "application/json",
      });
      if (token) {
        headers.append("Authorization", `Bearer ${token}`);
      }

      const response = await fetch("/api/v1/projects", {
        method: "GET",
        headers: headers,
      });

      if (response.ok) {
        const data: ProjectsResponse = await response.json();
        setAuthError("");
        localStorage.setItem("project", JSON.stringify(data.projects[0]));
        const project_id = data.projects[0].project_id;
        localStorage.setItem("project_id", project_id);
        setIsLoggedIn(true);
        navigate(`/projects/${project_id}/home`);
      } else {
        setIsLoggedIn(false);
        setIsLoadingProject(false);
        focusOn(emailInputRef);
      }
    } catch (error: any) {
      if (
        (!otpState && error.message === "HTTP error! status: 403") ||
        error.message === "HTTP error! status: 404"
      ) {
        navigate("/404");
      } else if (error.message.startsWith("HTTP error! status: 5")) {
        navigate("/5xx");
      }
    } finally {
      NProgress.done();
    }
  };

  const fetchPlannedOutages = async (): Promise<void> => {
    try {
      console.log("Fetching planned outages...");
      const response = await fetch("/api/v1/public/news");
      const data: { planned_outage?: PlannedOutage[] } = await response.json();
      const outages =
        data.planned_outage?.filter((item) => item.state?.name != "Done") || [];
      setPlannedOutages(outages);
    } catch (error) {
      console.error("Failed to fetch planned outages:", error);
    }
  };

  useEffect(() => {
    checkLoggedInStatus();
    fetchPlannedOutages();
    if (isLoggedIn) {
      setIsLoadingProject(false);
    } else {
      setIsLoadingProject(true);
    }
  }, [navigate]);

  const focusOn = (
    inputRef: React.RefObject<HTMLInputElement | null>
  ): void => {
    setTimeout(() => {
      if (inputRef.current) {
        inputRef.current.focus();
      }
    }, 0);
  };

  const emailIsValid = (): boolean => {
    const pattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return pattern.test(email);
  };

  const otpIsValid = (): boolean => {
    const pattern = /^\d{6}$/;
    return pattern.test(otp);
  };

  const getOtp = async () => {
    NProgress.start();
    if (!emailIsValid()) {
      NProgress.done();
      setAuthError("Please enter a valid email address.");
      return;
    }

    try {
      setAuthError("Mailing you an OTP...");
      const response = await fetch(
        `/api/v1/user/otp?email=${encodeURIComponent(email)}`
      );
      const data = await response.json();
      if (response.ok) {
        setOtpState(true);
        setAuthError("");
        localStorage.setItem("session_secret", data.session_secret);
        focusOn(otpInputRef);
      } else {
        setOtpState(false);
        setAuthError(data.title || "OTP request failed");
        focusOn(emailInputRef);
      }
    } catch (error) {
      setOtpState(false);
      setAuthError("Failed to request OTP");
      focusOn(emailInputRef);
    } finally {
      NProgress.done();
    }
  };

  const verifyOtpAndLogin = async (): Promise<void> => {
    NProgress.start();
    if (!otpIsValid()) {
      NProgress.done();
      setAuthError("Please enter a valid 6-digit OTP.");
      return;
    }
    try {
      setAuthError("Logging you in...");
      const session_secret = localStorage.getItem("session_secret");
      if (!session_secret) {
        NProgress.done();
        setAuthError("Please start your flow again.");
        setOtpState(false);
        return;
      }
      const response = await fetch(
        `/api/v1/user/token?otp=${encodeURIComponent(
          otp
        )}&session_secret=${encodeURIComponent(session_secret)}`
      );
      const data: TokenResponse = await response.json();
      if (response.ok) {
        localStorage.setItem("token", data.token);
        localStorage.setItem("me", JSON.stringify(jwtDecode(data.me)));
        setAuthError("");
        setIsLoggedIn(true);
        const project = await fetchDefaultProject();
        navigate(`/projects/${project}/home`);
      } else {
        setIsLoggedIn(false);
        setOtpState(false);
        setAuthError(data.title || "OTP request failed. Please login again.");
        focusOn(emailInputRef);
      }
    } catch (error) {
      setIsLoggedIn(false);
      setOtpState(false);
      setAuthError("Failed to verify OTP");
      focusOn(emailInputRef);
    } finally {
      NProgress.done();
      localStorage.removeItem("session_secret");
    }
  };

  const fetchDefaultProject = async (): Promise<string> => {
    try {
      const token = localStorage.getItem("token");
      const headers = new Headers({
        "Content-Type": "application/json",
      });
      if (token) {
        headers.append("Authorization", `Bearer ${token}`);
      }

      const response = await fetch("/api/v1/projects", {
        method: "GET",
        headers: headers,
      });

      if (response.ok) {
        const data: ProjectsResponse = await response.json();
        localStorage.setItem("project", JSON.stringify(data.projects[0]));
        const project_id = data.projects[0].project_id;
        localStorage.setItem("project_id", project_id);
        return project_id;
      } else {
        throw new Error("Failed to fetch default project");
      }
    } catch (error: any) {
      if (!otpState && error.message === "HTTP error! status: 403") {
        logout();
      } else if (error.message.startsWith("HTTP error! status: 5")) {
        navigate("/5xx");
      }
      throw error;
    }
  };

  const logout = async () => {
    setIsLoggedIn(false);
    setAuthError("Not logged in");
    setOtpState(false);
    try {
      await localStorage.clear();
    } catch (error) {
      console.error("Error clearing local storage:", error);
    }
    navigate("/");
  };

  const renderOutages = () => {
    return (
      <div className="mt-8 max-w-md mx-auto">
        {plannedOutages.length > 0 && (
          <h2 className="text-xl font-semibold mb-4 text-center">
            Planned Outages
          </h2>
        )}
        {plannedOutages.map((outage) => (
          <div
            key={outage.id}
            className="bg-gray-50 rounded-lg p-4 mb-4 shadow-sm"
          >
            <h3 className="font-semibold text-gray-900">{outage.title}</h3>
            <p className="text-sm text-gray-600 mt-1">{outage.createdAt}</p>
            <p className="text-gray-700 mt-2">{outage.description}</p>
            <div className="mt-2">
              <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-blue-100 text-blue-800">
                {outage.state.name}
              </span>
            </div>
          </div>
        ))}
      </div>
    );
  };

  if (isLoadingProject) {
    return (
      <div className="flex items-center justify-center h-screen">
        <div className="flex flex-row">
          <Loader2Icon className="mr-4 animate-spin" /> Loading...
        </div>
      </div>
    );
  }

  return (
    <div className="w-full h-full lg:grid lg:grid-cols-2 flex flex-col min-h-screen">
      <div className="relative hidden h-full flex-col bg-muted p-10 text-white dark:border-r lg:flex">
        <div className="absolute inset-0 bg-zinc-900" />
        <AIHero />
        <div className="relative z-20 mt-auto mx-auto">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 240 300"
            fill="none"
            stroke="currentColor"
            strokeWidth="3"
            strokeLinecap="round"
            strokeLinejoin="round"
            className="mr-2 h-20 w-20"
          >
            <path d="M120 20l100 200H20L120 20z">
              <animate
                attributeName="transform"
                type="translate"
                values="0,0; 0,-100; 0,0"
                dur="5s"
                repeatCount="indefinite"
              />
            </path>
            <line x1="20" y1="280" x2="220" y2="280" />
          </svg>
        </div>
        <div className="relative z-20 mt-auto">
          <blockquote className="space-y-2">
            <p className="text-xs">
              A.I. Hero, Inc. © {new Date().getFullYear()} All rights reserved.
            </p>
          </blockquote>
        </div>
      </div>
      <div className="flex flex-col items-center justify-center py-12">
        <div className="mx-auto grid w-[350px] gap-6">
          <div className="grid gap-2 text-center">
            <h1 className="text-3xl font-bold">Login</h1>
            {!otpState && (
              <p className="text-balance text-muted-foreground">
                Enter your email below to login to your account
              </p>
            )}
            {otpState && (
              <p className="text-balance text-muted-foreground">
                We've sent you a one-time password to your email. Enter it here.
              </p>
            )}
          </div>
          <div className="grid gap-4">
            {authError && (
              <div className="text-red-500 text-sm text-center">
                {authError}
              </div>
            )}
            {!otpState && (
              <div className="grid gap-2">
                <Input
                  id="email"
                  type="email"
                  placeholder="me@example.com"
                  className="text-center"
                  required
                  ref={emailInputRef}
                  value={email}
                  onChange={(e) => setEmail(e.currentTarget.value)}
                />
              </div>
            )}
            {otpState && (
              <div className="grid gap-2">
                <Input
                  id="otp"
                  type="text"
                  placeholder="Enter 6-digit OTP"
                  className="text-center"
                  required
                  ref={otpInputRef}
                  value={otp}
                  onChange={(e) => setOtp(e.currentTarget.value)}
                />
              </div>
            )}
            <Button
              onClick={otpState ? verifyOtpAndLogin : getOtp}
              type="submit"
              className="w-full"
            >
              {otpState ? "Verify OTP" : "Send a One-time Password (OTP)"}
            </Button>
          </div>
        </div>
        {renderOutages()}
      </div>
    </div>
  );
};

export default Main;
