import React, { useState } from "react";
import { PlusIcon, TrashIcon, Pencil } from "lucide-react";
import { Input } from "@/components/ui/input";
import { Button } from "@/components/ui/button";
import { Variable } from "@/types";
import { useToast } from "@/components/ui/use-toast";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogFooter,
} from "@/components/ui/dialog";

interface Inputs {
  variables: Variable[];
}

interface InputsComponentProps {
  inputs: Inputs;
  updateInputs: CallableFunction;
}

interface EditingVariable {
  originalKey: string;
  name: string;
  description: string;
  value: string;
}

export const RoutineInputs: React.FC<InputsComponentProps> = ({
  inputs,
  updateInputs,
}) => {
  const [showForm, setShowForm] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [editingVariable, setEditingVariable] =
    useState<EditingVariable | null>(null);
  const [isOpen, setIsOpen] = useState(false);
  const [toBeDeleted, setToBeDeleted] = useState("");
  const { toast } = useToast();

  const [newVariable, setNewVariable] = useState({
    name: "",
    description: "",
    value: "",
  });

  const handleSave = () => {
    if (!newVariable.name || !newVariable.description) return;

    const updatedInputs = { ...inputs };
    updatedInputs.variables = [
      ...(updatedInputs.variables || []),
      {
        name: newVariable.name,
        description: newVariable.description,
        value: newVariable.value,
      },
    ];

    updateInputs(updatedInputs);
    setShowForm(false);
    setNewVariable({
      name: "",
      description: "",
      value: "",
    });
  };

  const handleDelete = (name: string) => {
    const updatedInputs = { ...inputs };
    updatedInputs.variables = updatedInputs.variables.filter(
      (v) => v.name !== name
    );
    updateInputs(updatedInputs);
    setIsOpen(false);

    toast({
      description: (
        <div className="flex items-center">
          <TrashIcon className="mr-2 h-4 w-4" />
          Variable deleted
        </div>
      ),
    });
  };

  const handleEdit = (variable: Variable) => {
    setEditingVariable({
      originalKey: variable.name,
      name: variable.name,
      description: variable.description,
      value: variable.value ?? "",
    });
    setEditMode(true);
  };

  const handleSaveEdit = () => {
    if (!editingVariable) return;

    const updatedInputs = { ...inputs };
    const index = updatedInputs.variables.findIndex(
      (v) => v.name === editingVariable.originalKey
    );

    if (index !== -1) {
      updatedInputs.variables[index] = {
        name: editingVariable.name,
        description: editingVariable.description,
        value: editingVariable.value,
      };
    }

    updateInputs(updatedInputs);
    setEditMode(false);
    setEditingVariable(null);
  };

  return (
    <div className="space-y-4">
      <div className="flex justify-between items-center">
        <h2 className="text-sm font-semibold">VARIABLES</h2>
        <Button
          size="sm"
          variant="outline"
          onClick={() => setShowForm(true)}
          className="h-8"
        >
          <PlusIcon className="h-4 w-4 mr-2" />
          Add
        </Button>
      </div>

      {/* Variables Table */}
      {inputs.variables && inputs.variables.length > 0 ? (
        <div className="rounded-md border">
          <div className="grid grid-cols-10 gap-2 p-2 bg-gray-50 text-sm font-medium text-gray-500">
            <div className="col-span-2">Name</div>
            <div className="col-span-4">Description</div>
            <div className="col-span-3">Default Value</div>
            <div className="col-span-1"></div>
          </div>
          <div className="divide-y">
            {inputs.variables.map((variable) => (
              <div
                key={variable.name}
                className="grid grid-cols-10 gap-2 p-2 hover:bg-gray-50 items-center text-sm"
              >
                <div className="col-span-2 font-medium">{variable.name}</div>
                <div className="col-span-4 text-gray-600">
                  {variable.description}
                </div>
                <div className="col-span-3 text-gray-600">
                  {variable.value || (
                    <span className="text-orange-600 text-xs">No value</span>
                  )}
                </div>
                <div className="col-span-1 flex justify-end space-x-1">
                  <Button
                    variant="ghost"
                    size="sm"
                    className="h-7 w-7 p-0"
                    onClick={() => handleEdit(variable)}
                  >
                    <Pencil className="h-4 w-4" />
                  </Button>
                  <Button
                    variant="ghost"
                    size="sm"
                    className="h-7 w-7 p-0"
                    onClick={() => {
                      setToBeDeleted(variable.name);
                      setIsOpen(true);
                    }}
                  >
                    <TrashIcon className="h-4 w-4" />
                  </Button>
                </div>
              </div>
            ))}
          </div>
        </div>
      ) : (
        <div className="text-sm text-gray-500 italic">No variables defined</div>
      )}

      {/* Add/Edit Form Dialog */}
      <Dialog
        open={showForm || editMode}
        onOpenChange={(open) => {
          if (!open) {
            setShowForm(false);
            setEditMode(false);
            setEditingVariable(null);
          }
        }}
      >
        <DialogContent>
          <DialogHeader>
            <DialogTitle>
              {editMode ? "Edit Variable" : "Add Variable"}
            </DialogTitle>
          </DialogHeader>
          <div className="space-y-4 py-4">
            <div className="space-y-2">
              <Input
                placeholder="Name"
                value={editMode ? editingVariable?.name : newVariable.name}
                onChange={(e) => {
                  if (editMode && editingVariable) {
                    setEditingVariable({
                      ...editingVariable,
                      name: e.target.value,
                    });
                  } else {
                    setNewVariable({ ...newVariable, name: e.target.value });
                  }
                }}
              />
              <Input
                placeholder="Description"
                value={
                  editMode
                    ? editingVariable?.description
                    : newVariable.description
                }
                onChange={(e) => {
                  if (editMode && editingVariable) {
                    setEditingVariable({
                      ...editingVariable,
                      description: e.target.value,
                    });
                  } else {
                    setNewVariable({
                      ...newVariable,
                      description: e.target.value,
                    });
                  }
                }}
              />
              <Input
                placeholder="Default value"
                value={
                  editMode
                    ? (editingVariable?.value as string)
                    : newVariable.value
                }
                onChange={(e) => {
                  if (editMode && editingVariable) {
                    setEditingVariable({
                      ...editingVariable,
                      value: e.target.value,
                    });
                  } else {
                    setNewVariable({ ...newVariable, value: e.target.value });
                  }
                }}
              />
            </div>
          </div>
          <DialogFooter>
            <Button
              variant="outline"
              onClick={() => {
                setShowForm(false);
                setEditMode(false);
                setEditingVariable(null);
              }}
            >
              Cancel
            </Button>
            <Button onClick={editMode ? handleSaveEdit : handleSave}>
              {editMode ? "Save Changes" : "Add Variable"}
            </Button>
          </DialogFooter>
        </DialogContent>
      </Dialog>

      {/* Delete Confirmation Dialog */}
      <Dialog open={isOpen} onOpenChange={setIsOpen}>
        <DialogContent>
          <DialogHeader>
            <DialogTitle>Delete Variable</DialogTitle>
            <DialogDescription>
              Are you sure you want to delete this variable? This action cannot
              be undone.
            </DialogDescription>
          </DialogHeader>
          <DialogFooter>
            <Button variant="outline" onClick={() => setIsOpen(false)}>
              Cancel
            </Button>
            <Button
              variant="destructive"
              onClick={() => handleDelete(toBeDeleted)}
            >
              Delete
            </Button>
          </DialogFooter>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default RoutineInputs;
