import { useState, useEffect, useRef } from "react";
import { AIHero } from "@/components/common/ai-hero";
import { ResponsiveNav } from "@/components/common/responsive-nav";
import { UserNav } from "@/components/common/user-nav";
import { BackNav } from "@/components/common/back-nav";
import { Button } from "@/components/ui/button";
import { Badge } from "@/components/ui/badge";
import { useParams, useNavigate } from "react-router-dom";
import { Routine, InstructionStep, NoteStep } from "@/types";
import { InstructionCell } from "@/components/pages/single_routine_run/components/instruction";
import { NotesCell } from "@/components/pages/single_routine_run/components/notes";
import { Loader2Icon } from "lucide-react";
import CanvasCell from "./components/canvas";
import { fetchWithProgress } from "@/hooks/useFetchWithProgress";
import { Variable } from "@/types";

const SingleRoutineRun = () => {
  const [routine, setRoutine] = useState<Routine>();
  const [loading, setLoading] = useState(true);
  const [error] = useState<string>("");
  const [routineRunStatus, setRoutineRunStatus] = useState<string>("");
  const [routineRun, setRoutineRun] = useState<any>(null);
  const timeoutRef = useRef<NodeJS.Timeout | null>(null);

  const { project_id, routine_id, run_id } = useParams<{
    project_id: string;
    routine_id: string;
    run_id: string;
  }>();
  const navigate = useNavigate();

  useEffect(() => {
    fetchRoutine();
    if (run_id) {
      // Clear any existing timeout before starting new polling
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
        timeoutRef.current = null;
      }
      fetchRoutineRunStatus();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [routine_id, run_id]);

  useEffect(() => {
    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    };
  }, []);

  const fetchRoutine = async () => {
    try {
      const response = await fetchWithProgress(
        `/api/v1/projects/${project_id}/autonomous/routines/${routine_id}`,
        {
          method: "GET",
        },
        navigate
      );

      const data: Routine = await response.json();
      setRoutine(data);
      setLoading(false);
    } catch (error: any) {
      console.error("Failed to load the routine:", error);
      setLoading(false);
    }
  };

  const fetchRoutineRunStatus = async () => {
    if (!project_id || !routine_id || !run_id) return;
    try {
      const response = await fetchWithProgress(
        `/api/v1/projects/${project_id}/autonomous/routines/${routine_id}/routine_runs/${run_id}`,
        { method: "GET" },
        navigate,
        true,
        true,
        false
      );
      if (response.ok) {
        const data = await response.json();
        setRoutineRunStatus(data.state || "");
        setRoutineRun(data);

        // If the state ends in "ing", set up the next poll
        if (data.state?.endsWith("ing")) {
          timeoutRef.current = setTimeout(fetchRoutineRunStatus, 1000);
        }
      }
    } catch (error) {
      console.error("Failed to fetch routine run status:", error);
    }
  };

  const abort = async () => {
    if (!project_id || !routine_id || !run_id) return;
    try {
      // Clear existing timeout before abort
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
        timeoutRef.current = null;
      }

      const response = await fetchWithProgress(
        `/api/v1/projects/${project_id}/autonomous/routines/${routine_id}/routine_runs/${run_id}/abort`,
        {
          method: "POST",
        },
        navigate
      );

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      fetchRoutineRunStatus();
    } catch (error: any) {
      console.error("Failed to abort the routine run:", error);
    }
  };

  const navigateToRuns = () => {
    if (!project_id || !routine_id) return;
    navigate(`/projects/${project_id}/routines/${routine_id}/routine_runs`);
  };

  const replaceWithVariables = (content: string) => {
    if (!routine || !content) return content;
    return content.replace(/\{(\w+)\}/g, (match, varName) => {
      console.log(match);
      const variables =
        routineRun?.inputs?.variables || routine.inputs?.variables || [];
      const variable = variables.find((v: any) => v.name === varName);
      const value = variable?.value;
      if (value) {
        return `<span class="font-semibold">${String(value).trim()}</span>`;
      }
      return `<span class="text-muted-foreground">{${varName}}</span>`;
    });
  };

  const displayName = routine ? replaceWithVariables(routine.name) : "";
  const displayTask = routine?.task ? replaceWithVariables(routine.task) : "";

  if (loading) {
    return (
      <div className="flex items-center justify-center h-screen">
        <div className="flex flex-row">
          <Loader2Icon className="mr-4 animate-spin" /> Loading...
        </div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="flex items-center justify-center h-screen">
        <div className="flex flex-row">Error: {error}</div>
      </div>
    );
  }

  if (!routine) {
    return (
      <div className="flex items-center justify-center h-screen">
        <div className="flex flex-row">No routine found</div>
      </div>
    );
  }

  return (
    <>
      <header className="flex h-16 items-center justify-between border-b bg-background px-6">
        <nav className="hidden md:flex space-x-6">
          <AIHero />
          <BackNav
            className="mx-6"
            parent={`routines/${routine_id}/routine_runs`}
          />
        </nav>
        <ResponsiveNav />
        <div className="ml-auto flex items-center space-x-4">
          {routineRunStatus && (
            <Badge variant="outline">{routineRunStatus}</Badge>
          )}
          {routineRunStatus?.endsWith("ing") && (
            <Button variant="destructive" onClick={abort}>
              Abort Run
            </Button>
          )}
          <Button
            variant="outline"
            className="text-sm"
            onClick={navigateToRuns}
          >
            See all runs
          </Button>
          <UserNav />
        </div>
      </header>

      <main className="flex flex-grow overflow-x-hidden px-4 grid grid-cols-5">
        <div className="col-span-2 p-4">
          <div className="items-center justify-between grid w-full">
            {displayTask && (
              <h3
                className="text-sm"
                dangerouslySetInnerHTML={{
                  __html: replaceWithVariables(displayTask),
                }}
              ></h3>
            )}
            <div className="flex items-center space-x-4 text-2xl font-semibold w-full my-2">
              <span
                dangerouslySetInnerHTML={{
                  __html: replaceWithVariables(displayName),
                }}
              ></span>
            </div>
          </div>
          <div className="w-full">
            <div className="my-4">
              <h3 className="text-sm font-semibold mb-2">INPUTS</h3>
              <div className="space-y-2">
                {(
                  routineRun?.inputs?.variables ||
                  routine?.inputs?.variables ||
                  []
                ).map((variable: Variable, index: number) => (
                  <div
                    key={index}
                    className="flex items-center justify-between"
                  >
                    <span className="text-sm text-muted-foreground">
                      {variable.name}:
                    </span>
                    <span className="text-sm font-medium">
                      {variable.value || (
                        <span className="text-muted-foreground">Not set</span>
                      )}
                    </span>
                  </div>
                ))}
              </div>
            </div>
            <h2 className="my-4 text-sm font-semibold">PLAN</h2>
            {routine.steps.map((step, index) => (
              <div className="mb-2" id={String(index)} key={index}>
                {step.type === "instruction" ? (
                  <InstructionCell
                    step={step as InstructionStep}
                    variables={
                      routineRun?.inputs?.variables ||
                      routine?.inputs?.variables ||
                      []
                    }
                    routineRunStep={routineRun?.steps?.[index]}
                  />
                ) : step.type === "note" ? (
                  <NotesCell
                    step={step as NoteStep}
                    routineRunStep={routineRun?.steps?.[index]}
                  />
                ) : null}
              </div>
            ))}
          </div>
        </div>
        <div className="col-span-3 p-4 border-l">
          {routineRun?.canvas ? (
            <CanvasCell canvas={routineRun.canvas} />
          ) : (
            <div className="text-muted-foreground text-center h-full flex items-center justify-center">
              No output available for this run yet.
            </div>
          )}
        </div>
      </main>
    </>
  );
};

export default SingleRoutineRun;
