import "@/App.css";

import { Route, Routes } from "react-router-dom";

import Home from "@/components/pages/home";
import Settings from "@/components/pages/settings";
import Main from "@/components/pages/main";
import { Toaster } from "@/components/ui/toaster";
import { NotFound } from "@/components/pages/404";
import { PaymentRequired } from "@/components/pages/402";
import { ServerError } from "@/components/pages/5xx";
import Routines from "@/components/pages/routines";
import SingleRoutine from "./components/pages/single_routine";
import { ThemeProvider } from "@/components/common/theme-provider";
import RoutineRuns from "./components/pages/routine_runs";
import SingleRoutineRun from "./components/pages/single_routine_run";

import * as Sentry from "@sentry/react";

Sentry.init({
  dsn: "https://2ef6bb931708bb57725c77058aa3c26e@o4507165202513920.ingest.us.sentry.io/4507684885102592",
  integrations: [],
});

function App() {
  if (process.env.NODE_ENV === "production") {
    console.log = () => {};
    console.error = () => {};
    console.debug = () => {};
  }

  return (
    <>
      <ThemeProvider defaultTheme="system" storageKey="vite-ui-theme">
        <Routes>
          <Route path="/" element={<Main />} />
          <Route path="/404" element={<NotFound />} />
          <Route path="/402" element={<PaymentRequired />} />
          <Route path="/5xx" element={<ServerError />} />
          <Route path="/projects/:project_id/home" element={<Home />} />
          <Route path="/projects/:project_id/settings" element={<Settings />} />
          <Route path="/projects/:project_id/routines" element={<Routines />} />
          <Route
            path="/projects/:project_id/routines/:routine_id"
            element={<SingleRoutine />}
          />
          <Route
            path="/projects/:project_id/routines/:routine_id/routine_runs"
            element={<RoutineRuns />}
          />
          <Route
            path="/projects/:project_id/routines/:routine_id/routine_runs/:run_id"
            element={<SingleRoutineRun />}
          />
          <Route path="*" element={<NotFound />} />
        </Routes>
        <Toaster />
      </ThemeProvider>
    </>
  );
}

export default App;
