import { useState, useEffect } from "react";
import { PlusIcon, TrashIcon, Pencil } from "lucide-react";
import { Input } from "@/components/ui/input";
import { Button } from "@/components/ui/button";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogFooter,
} from "@/components/ui/dialog";
import { useToast } from "@/components/ui/use-toast";
import { Inputs, Variable } from "@/types";

interface EditingVariable extends Variable {
  originalKey: string;
}

interface RoutineInputsProps {
  inputs: Inputs;
  updateInputs: (inputs: Inputs) => void;
}

const RoutineInputs: React.FC<RoutineInputsProps> = ({
  inputs,
  updateInputs,
}) => {
  const [showForm, setShowForm] = useState<boolean>(false);
  const [editMode, setEditMode] = useState<boolean>(false);
  const [editingVariable, setEditingVariable] =
    useState<EditingVariable | null>(null);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [toBeDeleted, setToBeDeleted] = useState<string>("");
  const { toast } = useToast();

  const [newVariable, setNewVariable] = useState<Variable>({
    name: "",
    description: "",
    value: "",
  });

  useEffect(() => {
    if (!inputs.variables) {
      updateInputs({ ...inputs, variables: [] });
    }
  }, [inputs, updateInputs]);

  const handleSave = (): void => {
    if (!newVariable.name || !newVariable.description) return;

    const updatedVariables: Variable[] = [
      ...(inputs.variables || []),
      {
        name: newVariable.name,
        description: newVariable.description,
        value: newVariable.value || "",
      },
    ];

    updateInputs({ ...inputs, variables: updatedVariables });

    setShowForm(false);
    setNewVariable({
      name: "",
      description: "",
      value: "",
    });
  };

  const handleDelete = (name: string): void => {
    const updatedVariables = inputs.variables.filter((v) => v.name !== name);
    updateInputs({ ...inputs, variables: updatedVariables });
    setIsOpen(false);

    toast({
      description: (
        <div className="flex items-center">
          <TrashIcon className="mr-2 h-4 w-4" />
          Variable deleted
        </div>
      ),
    });
  };

  const handleEdit = (variable: Variable): void => {
    setEditingVariable({
      originalKey: variable.name,
      name: variable.name,
      description: variable.description,
      value: variable.value || "",
    });
    setEditMode(true);
  };

  const handleSaveEdit = (): void => {
    if (!editingVariable) return;

    const updatedVariables = [...inputs.variables];
    const index = updatedVariables.findIndex(
      (v) => v.name === editingVariable.originalKey
    );

    if (index !== -1) {
      updatedVariables[index] = {
        name: editingVariable.name,
        description: editingVariable.description,
        value: editingVariable.value || "",
      };

      updateInputs({ ...inputs, variables: updatedVariables });
    }

    setEditMode(false);
    setEditingVariable(null);
  };

  return (
    <div className="space-y-4">
      <div className="flex justify-between items-center">
        <h2 className="text-sm font-semibold">VARIABLES</h2>
        <Button
          size="sm"
          variant="outline"
          onClick={() => setShowForm(true)}
          className="h-8"
        >
          <PlusIcon className="h-4 w-4 mr-2" />
          Add
        </Button>
      </div>

      {inputs.variables && inputs.variables.length > 0 ? (
        <div className="rounded-md border">
          <div className="grid grid-cols-10 gap-2 p-2 bg-gray-50 text-sm font-medium text-gray-500">
            <div className="col-span-2">Name</div>
            <div className="col-span-4">Description</div>
            <div className="col-span-3">Value</div>
            <div className="col-span-1"></div>
          </div>
          <div className="divide-y">
            {inputs.variables.map((variable) => (
              <div
                key={variable.name}
                className="grid grid-cols-10 gap-2 p-2 hover:bg-gray-50 items-center text-sm"
              >
                <div className="col-span-2 font-medium">{variable.name}</div>
                <div className="col-span-4 text-gray-600">
                  {variable.description}
                </div>
                <div className="col-span-3 text-gray-600">
                  {variable.value || (
                    <span className="text-orange-600 text-xs">No value</span>
                  )}
                </div>
                <div className="col-span-1 flex justify-end space-x-1">
                  <Button
                    variant="ghost"
                    size="sm"
                    className="h-7 w-7 p-0"
                    onClick={() => handleEdit(variable)}
                  >
                    <Pencil className="h-4 w-4" />
                  </Button>
                  <Button
                    variant="ghost"
                    size="sm"
                    className="h-7 w-7 p-0"
                    onClick={() => {
                      setToBeDeleted(variable.name);
                      setIsOpen(true);
                    }}
                  >
                    <TrashIcon className="h-4 w-4" />
                  </Button>
                </div>
              </div>
            ))}
          </div>
        </div>
      ) : (
        <div className="text-sm text-gray-500 italic">No variables defined</div>
      )}

      <Dialog
        open={showForm || editMode}
        onOpenChange={(open: boolean) => {
          if (!open) {
            setShowForm(false);
            setEditMode(false);
            setEditingVariable(null);
          }
        }}
      >
        <DialogContent>
          <DialogHeader>
            <DialogTitle>
              {editMode ? "Edit Variable" : "Add Variable"}
            </DialogTitle>
            <DialogDescription>
              {editMode
                ? "Modify the variable details below."
                : "Enter the details for the new variable."}
            </DialogDescription>
          </DialogHeader>
          <div className="space-y-4 py-4">
            <div className="space-y-2">
              <Input
                placeholder="Name"
                value={editMode ? editingVariable?.name : newVariable.name}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  if (editMode && editingVariable) {
                    setEditingVariable({
                      ...editingVariable,
                      name: e.target.value,
                    });
                  } else {
                    setNewVariable({ ...newVariable, name: e.target.value });
                  }
                }}
              />
              <Input
                placeholder="Description"
                value={
                  editMode
                    ? editingVariable?.description
                    : newVariable.description
                }
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  if (editMode && editingVariable) {
                    setEditingVariable({
                      ...editingVariable,
                      description: e.target.value,
                    });
                  } else {
                    setNewVariable({
                      ...newVariable,
                      description: e.target.value,
                    });
                  }
                }}
              />
              <Input
                placeholder="Default value"
                value={editMode ? editingVariable?.value : newVariable.value}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  if (editMode && editingVariable) {
                    setEditingVariable({
                      ...editingVariable,
                      value: e.target.value,
                    });
                  } else {
                    setNewVariable({ ...newVariable, value: e.target.value });
                  }
                }}
              />
            </div>
          </div>
          <DialogFooter>
            <Button
              variant="outline"
              onClick={() => {
                setShowForm(false);
                setEditMode(false);
                setEditingVariable(null);
              }}
            >
              Cancel
            </Button>
            <Button onClick={editMode ? handleSaveEdit : handleSave}>
              {editMode ? "Save Changes" : "Add Variable"}
            </Button>
          </DialogFooter>
        </DialogContent>
      </Dialog>

      <Dialog open={isOpen} onOpenChange={setIsOpen}>
        <DialogContent>
          <DialogHeader>
            <DialogTitle>Delete Variable</DialogTitle>
            <DialogDescription>
              Are you sure you want to delete this variable? This action cannot
              be undone.
            </DialogDescription>
          </DialogHeader>
          <DialogFooter>
            <Button variant="outline" onClick={() => setIsOpen(false)}>
              Cancel
            </Button>
            <Button
              variant="destructive"
              onClick={() => handleDelete(toBeDeleted)}
            >
              Delete
            </Button>
          </DialogFooter>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default RoutineInputs;
