import React from "react";
import { marked } from "marked";
import { SparklesIcon, CheckIcon, XIcon, BoltIcon } from "lucide-react";
import { InstructionStep, ModeEnum, Variable } from "@/types";

interface InstructionCellProps {
  step: InstructionStep;
  variables: Variable[];
  routineRunStep: InstructionStep;
}

marked.use({
  gfm: true,
  breaks: true,
});

export const InstructionCell: React.FC<InstructionCellProps> = ({
  step,
  variables,
  routineRunStep,
}) => {
  const displayVariableValue = (content: string) => {
    if (!content) return content;
    return content.replace(/\{(\w+)\}/g, (_match, varName) => {
      const variable = variables.find((v) => v.name === varName);
      const value = variable?.value;
      if (value) {
        return `<span class="font-semibold">${String(value).trim()}</span>`;
      }
      return `<span class="text-muted-foreground">{${varName}}</span>`;
    });
  };

  const renderMarkdown = (content: string) => {
    const contentWithVariables = displayVariableValue(content);
    return { __html: marked(contentWithVariables) };
  };

  if (
    routineRunStep?.mode === ModeEnum.PROCESSING ||
    step.mode === ModeEnum.PROCESSING
  ) {
    return (
      <div className="w-full px-2">
        <span className="flex text-sm pt-3 pb-2 mb-2 bg-gray-200 w-full">
          <SparklesIcon className="min-w-4 min-h-4 max-w-4 max-h-4 mr-2 mt-1 text-hero-600" />
          <div
            dangerouslySetInnerHTML={renderMarkdown(
              routineRunStep?.instruction || step.instruction
            )}
          />
        </span>
        <div className="flex items-top px-3 flex-col">
          <div className="animate-pulse mt-2 space-y-4">
            <div className="h-4 bg-gray-300 rounded w-full"></div>
            <div className="h-4 bg-gray-300 rounded w-3/4"></div>
          </div>
        </div>
      </div>
    );
  }

  if (
    routineRunStep?.mode === ModeEnum.IMPROVE ||
    routineRunStep?.mode === ModeEnum.IMPROVING ||
    step.mode === ModeEnum.IMPROVE ||
    step.mode === ModeEnum.IMPROVING
  ) {
    return (
      <div className="w-full px-2">
        <span className="flex text-sm pt-3 pb-2 mb-2 bg-gray-200 w-full">
          <BoltIcon className="min-w-4 h-4 mx-2 mt-1 text-hero-600" />
          <div className="flex">
            Working on your feedback `
            {routineRunStep?.feedback || step.feedback}` for the instruction: `
            {routineRunStep?.instruction || step.instruction}`
          </div>
        </span>
        <div className="animate-pulse pt-2 my-2 space-y-4">
          <div className="h-4 bg-gray-300 rounded w-full"></div>
          <div className="h-4 bg-gray-300 rounded w-3/4"></div>
        </div>
      </div>
    );
  }

  // OUTPUT mode (default)
  return (
    <div className="w-full px-2">
      <div className="items-top gap-4">
        <div className="flex items-top p-3 bg-gray-100 border">
          <SparklesIcon className="min-w-4 min-h-4 max-w-4 max-h-4 mr-2 mt-1 text-slate-800" />
          <div
            dangerouslySetInnerHTML={renderMarkdown(
              routineRunStep?.instruction || step.instruction
            )}
            className="flex-grow"
          />
        </div>
      </div>
      {!(routineRunStep?.mute || step.mute) &&
        (routineRunStep?.message || step.message) && (
          <div className="p-2 ml-2 flex items-top gap-4 text-muted-foreground">
            {routineRunStep?.error ? (
              <XIcon className="mt-2 min-w-3 min-h-3 max-h-3 max-w-3 text-red-600" />
            ) : (
              <CheckIcon className="mt-2 min-w-3 min-h-3 max-h-3 max-w-3 text-green-600" />
            )}
            <div
              dangerouslySetInnerHTML={renderMarkdown(
                routineRunStep?.message ||
                  step.message ||
                  "Performing sub-task..."
              )}
              className="flex-grow"
            />
          </div>
        )}
      {(routineRunStep?.error || step.error) && (
        <div className="text-xs text-red-500">
          {routineRunStep?.error || step.error}
        </div>
      )}
    </div>
  );
};
