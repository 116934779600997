import { useState, useEffect } from "react";
import { AIHero } from "@/components/common/ai-hero";
import { MainNav } from "@/components/common/main-nav";
import { ResponsiveNav } from "@/components/common/responsive-nav";
import { UserNav } from "@/components/common/user-nav";
import {
  Card,
  CardHeader,
  CardTitle,
  CardContent,
  CardDescription,
} from "@/components/ui/card";
import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import { Loader2, Wand2, Book } from "lucide-react";
import { fetchWithProgress } from "@/hooks/useFetchWithProgress";
import { useNavigate, useParams } from "react-router-dom";

interface Handbook {
  content: string;
  description: string;
  handbook_id: string;
  name: string;
  scope: string;
  domain: string;
}

interface HandbooksResponse {
  handbooks: Handbook[];
  pages: number;
}

const Home = () => {
  const navigate = useNavigate();
  const { project_id } = useParams<{ project_id: string }>();
  const [routineTask, setRoutineTask] = useState("");
  const [isGenerating, setIsGenerating] = useState(false);
  const [handbooks, setHandbooks] = useState<Handbook[]>([]);
  const [isLoadingHandbooks, setIsLoadingHandbooks] = useState(true);
  const [selectedDomain, setSelectedDomain] = useState<string>("");
  const [selectedHandbooks, setSelectedHandbooks] = useState<Set<string>>(
    new Set()
  );

  const domains = [
    "Content Marketing",
    "Social Media Marketing",
    "Advertising",
    "Email Marketing",
    "E-commerce",
    "Investor Relations",
    "Public Relations",
    "Content Strategy",
    "Sales Enablement",
    "Video Marketing",
    "Brand Management",
    "Talent Acquisition",
  ];

  // Single source of truth for fetching handbooks
  const fetchHandbooks = async (page = 1) => {
    try {
      setIsLoadingHandbooks(true);
      const limit = 8;
      const skip = (page - 1) * limit;

      const response = await fetchWithProgress(
        `/api/v1/projects/${project_id}/autonomous/handbooks?${
          selectedDomain ? `domain=${encodeURIComponent(selectedDomain)}&` : ""
        }limit=${limit}&skip=${skip}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        },
        navigate
      );
      const data: HandbooksResponse = await response.json();
      setHandbooks(data.handbooks);

      setSelectedHandbooks((prev) => {
        const newSet = new Set(prev);
        const availableIds = new Set(data.handbooks.map((h) => h.handbook_id));
        for (const id of prev) {
          if (!availableIds.has(id)) {
            newSet.delete(id);
          }
        }
        return newSet;
      });
    } catch (error) {
      console.error("Failed to load handbooks:", error);
    } finally {
      setIsLoadingHandbooks(false);
    }
  };

  // Initial fetch on component mount
  useEffect(() => {
    fetchHandbooks();
  }, [project_id]);

  // Fetch when domain selection changes
  useEffect(() => {
    fetchHandbooks();
  }, [selectedDomain]);

  const handleGenerate = async () => {
    if (!routineTask.trim() || selectedHandbooks.size === 0) return;

    try {
      setIsGenerating(true);
      const handbookIds = Array.from(selectedHandbooks)
        .map((id) => `handbook_ids=${encodeURIComponent(id)}`)
        .join("&");

      const response = await fetchWithProgress(
        `/api/v1/projects/${project_id}/autonomous/wizard?task=${encodeURIComponent(
          routineTask.trim()
        )}&${handbookIds}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        },
        navigate
      );

      const routine = await response.json();
      navigate(`/projects/${project_id}/routines/${routine.routine_id}`);
    } catch (error) {
      console.error("Failed to generate routine:", error);
    } finally {
      setIsGenerating(false);
    }
  };

  const handleKeyDown = (e: React.KeyboardEvent) => {
    if (e.key === "Enter" && !e.shiftKey) {
      e.preventDefault();
      handleGenerate();
    }
  };

  const toggleHandbook = (handbookId: string) => {
    setSelectedHandbooks((prev) => {
      const newSet = new Set(prev);
      if (newSet.has(handbookId)) {
        newSet.delete(handbookId);
      } else {
        newSet.add(handbookId);
      }
      return newSet;
    });
  };

  return (
    <>
      <header className="flex h-16 items-center justify-between border-b bg-background px-6">
        <nav className="hidden md:flex space-x-6">
          <AIHero />
          <MainNav />
        </nav>
        <ResponsiveNav />
        <div>
          <UserNav />
        </div>
      </header>
      <main className="container mx-auto p-6 mt-10">
        <div className="space-y-10">
          <Card>
            <CardHeader>
              <CardTitle className="text-3xl">Create New Routine</CardTitle>
              <CardDescription>
                This wizard helps you create an automated routine by combining
                your task with best practices from selected handbooks. The
                generated routine will break down your task into detailed,
                ordered steps with clear validation criteria.
              </CardDescription>
            </CardHeader>
            <CardContent className="space-y-6">
              <div className="space-y-6">
                <div className="space-y-2">
                  <label className="text-sm font-medium block">
                    Step 1: Select Handbooks
                  </label>
                  <p className="text-sm text-muted-foreground">
                    Choose handbooks that contain relevant guidelines and best
                    practices for your task. The wizard will use these to create
                    detailed, actionable steps.
                  </p>
                </div>
                {isLoadingHandbooks ? (
                  <div className="text-center py-8">
                    <Loader2 className="h-8 w-8 animate-spin mx-auto" />
                    <p className="mt-2 text-muted-foreground">
                      Loading handbooks...
                    </p>
                  </div>
                ) : (
                  <>
                    <div className="w-full max-w-xs">
                      <Select
                        value={selectedDomain}
                        onValueChange={setSelectedDomain}
                      >
                        <SelectTrigger>
                          <SelectValue placeholder="Filter by domain" />
                        </SelectTrigger>
                        <SelectContent>
                          {domains.map((domain) => (
                            <SelectItem key={domain} value={domain}>
                              {domain}
                            </SelectItem>
                          ))}
                        </SelectContent>
                      </Select>
                    </div>
                    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6">
                      {handbooks.map((handbook) => (
                        <Card
                          key={handbook.handbook_id}
                          className={`hover:bg-accent/50 transition-colors cursor-pointer ${
                            selectedHandbooks.has(handbook.handbook_id)
                              ? "border-primary border-2"
                              : ""
                          }`}
                          onClick={() => toggleHandbook(handbook.handbook_id)}
                        >
                          <CardHeader>
                            <CardTitle className="flex items-center space-x-2">
                              <Book
                                className={`h-5 w-5 ${
                                  selectedHandbooks.has(handbook.handbook_id)
                                    ? "text-primary"
                                    : ""
                                }`}
                              />
                              <span className="text-base">{handbook.name}</span>
                            </CardTitle>
                          </CardHeader>
                          <CardContent>
                            <p className="text-sm text-muted-foreground line-clamp-2">
                              {handbook.description}
                            </p>
                          </CardContent>
                        </Card>
                      ))}
                    </div>
                  </>
                )}
              </div>
              <div className="space-y-4">
                <div className="space-y-2">
                  <label className="text-sm font-medium block">
                    Step 2: Define Your Task
                  </label>
                  <p className="text-sm text-muted-foreground">
                    Describe what you want to accomplish. The wizard will create
                    a routine with input variables, step-by-step instructions,
                    and validation criteria based on your selected handbooks.
                  </p>
                </div>
                <div className="flex space-x-4">
                  <Input
                    placeholder="e.g., Write a blog post about artificial intelligence"
                    value={routineTask}
                    onChange={(e) => setRoutineTask(e.target.value)}
                    onKeyDown={handleKeyDown}
                    className="flex-1"
                    disabled={isGenerating}
                  />
                </div>
              </div>
              <Button
                onClick={handleGenerate}
                disabled={
                  !routineTask.trim() ||
                  selectedHandbooks.size === 0 ||
                  isGenerating ||
                  isLoadingHandbooks
                }
              >
                {isGenerating ? (
                  <>
                    <Loader2 className="mr-2 h-4 w-4 animate-spin" />
                    Generating...
                  </>
                ) : (
                  <>
                    <Wand2 className="mr-2 h-4 w-4" />
                    Generate Routine
                  </>
                )}
              </Button>
              {isGenerating && (
                <div className="rounded-lg bg-muted/50 p-8 text-center">
                  <Loader2 className="h-8 w-8 animate-spin mx-auto mb-4 text-primary" />
                  <h3 className="font-medium text-lg mb-2">
                    Creating Your Routine
                  </h3>
                  <p className="text-muted-foreground">
                    We're analyzing your selected handbooks and creating a
                    detailed routine with:
                  </p>
                  <ul className="text-sm text-muted-foreground mt-2 space-y-1">
                    <li>
                      • Step-by-step instructions with validation criteria
                    </li>
                    <li>• Input variables for customization</li>
                    <li>• Best practices from your chosen handbooks</li>
                  </ul>
                </div>
              )}
            </CardContent>
          </Card>
        </div>
      </main>
    </>
  );
};

export default Home;
