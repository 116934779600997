import { useState, useEffect } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { AIHero } from "@/components/common/ai-hero";
import { BackNav } from "@/components/common/back-nav";
import { ResponsiveNav } from "@/components/common/responsive-nav";
import { UserNav } from "@/components/common/user-nav";
import { ChevronLeft, ChevronRight } from "lucide-react";
import { Button } from "@/components/ui/button";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import { fetchWithProgress } from "@/hooks/useFetchWithProgress";
import { RoutineRun } from "@/types";

const PAGE_SIZES = [10, 20, 50, 100];
const DEFAULT_PAGE_SIZE = 10;

const RoutineRuns = () => {
  const { project_id, routine_id } = useParams();
  const [searchParams, setSearchParams] = useSearchParams();
  const [runs, setRuns] = useState<RoutineRun[]>([]);
  const [totalRuns, setTotalRuns] = useState(0);
  const [isFetching, setIsFetching] = useState(false);
  const navigate = useNavigate();

  const pageSize = parseInt(
    searchParams.get("limit") || String(DEFAULT_PAGE_SIZE)
  );
  const currentPage = parseInt(searchParams.get("page") || "1");
  const skip = (currentPage - 1) * pageSize;

  useEffect(() => {
    fetchRuns();
  }, [currentPage, pageSize]);

  const fetchRuns = async () => {
    setIsFetching(true);
    try {
      const response = await fetchWithProgress(
        `/api/v1/projects/${project_id}/autonomous/routines/${routine_id}/routine_runs?skip=${skip}&limit=${pageSize}`,
        { method: "GET" },
        navigate
      );
      if (response) {
        const data = await response.json();
        setRuns(data.routine_runs || []);
        setTotalRuns(data.pages * pageSize || 0);
      }
    } catch (error) {
      console.error("Failed to fetch routine runs", error);
    }
    setIsFetching(false);
  };

  const handlePageChange = (newPage: number) => {
    searchParams.set("page", newPage.toString());
    setSearchParams(searchParams);
  };

  const handlePageSizeChange = (newSize: string) => {
    searchParams.set("limit", newSize);
    searchParams.set("page", "1");
    setSearchParams(searchParams);
  };

  const getStatusColor = (status: string): string => {
    switch (status) {
      case "success":
        return "text-green-500";
      case "failed":
        return "text-red-500";
      case "running":
        return "text-blue-500";
      default:
        return "text-gray-500";
    }
  };

  const formatDate = (dateString: string): string => {
    return new Date(dateString).toLocaleString();
  };

  const getDuration = (startDate: string, endDate?: string): string => {
    if (!endDate) return "In progress";
    const start = new Date(startDate).getTime();
    const end = new Date(endDate).getTime();
    const durationMs = end - start;
    const seconds = Math.floor(durationMs / 1000);
    const minutes = Math.floor(seconds / 60);
    return minutes > 0 ? `${minutes}m ${seconds % 60}s` : `${seconds}s`;
  };

  const totalPages = Math.max(1, Math.ceil(totalRuns / pageSize));

  return (
    <>
      <header className="flex h-16 items-center justify-between border-b bg-background px-6">
        <nav className="hidden md:flex space-x-6">
          <AIHero />
          <BackNav className="mx-6" parent={`routines/${routine_id}`} />
        </nav>
        <ResponsiveNav />
        <UserNav />
      </header>

      <main className="container p-6">
        <div className="flex items-center justify-between mb-6">
          <div className="flex items-center space-x-4">
            <h1 className="text-3xl font-semibold">Routine Runs</h1>
          </div>
        </div>

        <div className="rounded-md border">
          <Table>
            <TableHeader>
              <TableRow>
                <TableHead>Run ID</TableHead>
                <TableHead>Status</TableHead>
                <TableHead>Model</TableHead>
                <TableHead>Started</TableHead>
                <TableHead>Duration</TableHead>
                <TableHead>Trigger</TableHead>
              </TableRow>
            </TableHeader>
            <TableBody>
              {isFetching ? (
                <TableRow>
                  <TableCell colSpan={6} className="text-center">
                    Loading runs...
                  </TableCell>
                </TableRow>
              ) : runs.length === 0 ? (
                <TableRow>
                  <TableCell colSpan={6} className="text-center">
                    No runs found for this routine.
                  </TableCell>
                </TableRow>
              ) : (
                runs.map((run) => (
                  <TableRow
                    key={run.run_id}
                    className="cursor-pointer hover:bg-muted"
                    onClick={() =>
                      navigate(
                        `/projects/${project_id}/routines/${routine_id}/routine_runs/${run.run_id}`
                      )
                    }
                  >
                    <TableCell>{run.run_id}</TableCell>
                    <TableCell>
                      <span className={getStatusColor(run.state)}>
                        {run.state.charAt(0).toUpperCase() + run.state.slice(1)}
                      </span>
                    </TableCell>
                    <TableCell>{run.model}</TableCell>
                    <TableCell>{formatDate(run.start_at)}</TableCell>
                    <TableCell>
                      {getDuration(run.start_at, run.end_at)}
                    </TableCell>
                    <TableCell>{run.trigger?.name || "-"}</TableCell>
                  </TableRow>
                ))
              )}
            </TableBody>
          </Table>
        </div>

        <div className="flex items-center justify-between space-x-2 py-4">
          <div className="flex items-center space-x-2">
            <p className="text-sm text-muted-foreground">
              Showing {Math.min(skip + 1, totalRuns)}-
              {Math.min(skip + pageSize, totalRuns)} of {totalRuns} results
            </p>
            <Select
              value={pageSize.toString()}
              onValueChange={handlePageSizeChange}
            >
              <SelectTrigger className="h-8 w-[70px]">
                <SelectValue />
              </SelectTrigger>
              <SelectContent side="top">
                {PAGE_SIZES.map((size) => (
                  <SelectItem key={size} value={size.toString()}>
                    {size}
                  </SelectItem>
                ))}
              </SelectContent>
            </Select>
          </div>
          <div className="flex items-center space-x-2">
            <Button
              variant="outline"
              size="icon"
              onClick={() => handlePageChange(currentPage - 1)}
              disabled={currentPage === 1}
            >
              <ChevronLeft className="h-4 w-4" />
            </Button>
            <div className="flex items-center">
              <span className="text-sm font-medium">
                Page {currentPage} of {totalPages}
              </span>
            </div>
            <Button
              variant="outline"
              size="icon"
              onClick={() => handlePageChange(currentPage + 1)}
              disabled={currentPage >= totalPages}
            >
              <ChevronRight className="h-4 w-4" />
            </Button>
          </div>
        </div>
      </main>
    </>
  );
};

export default RoutineRuns;
