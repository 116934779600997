import React, { useEffect } from "react";
import { marked } from "marked";
import { useCreateBlockNote } from "@blocknote/react";
import { BlockNoteView } from "@blocknote/shadcn";
import "@blocknote/shadcn/style.css";

interface CanvasCellProps {
  canvas: string[];
}

marked.use({
  gfm: true,
  breaks: true,
});

export const CanvasCell: React.FC<CanvasCellProps> = ({ canvas }) => {
  const editor = useCreateBlockNote();

  useEffect(() => {
    const updateEditorContent = async () => {
      if (editor) {
        // Join the canvas array with newlines to create a single markdown string
        const markdownContent = canvas.join("\n\n");

        if (markdownContent) {
          // Convert markdown to BlockNote blocks
          const blocksFromMarkdown = await editor.tryParseMarkdownToBlocks(
            markdownContent
          );
          // Replace the entire document content with new blocks
          editor.replaceBlocks(editor.document, blocksFromMarkdown);
        }
      }
    };

    updateEditorContent();
  }, [editor, canvas]); // Re-run when editor or canvas changes

  return (
    <div className="w-full">
      {editor && <BlockNoteView editor={editor} editable={false} />}
    </div>
  );
};

export default CanvasCell;
