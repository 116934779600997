import React, { useState, useEffect, useRef } from "react";
import { Button } from "@/components/ui/button";
import { Textarea } from "@/components/ui/textarea";
import { marked } from "marked";
import {
  SparklesIcon,
  CheckIcon,
  XIcon,
  TrashIcon,
  BoltIcon,
} from "lucide-react";
import { InstructionStep, ModeEnum, Variable } from "@/types";

interface InstructionCellProps {
  step: InstructionStep;
  updateStep: (updatedStep: InstructionStep) => void;
  deleteStep: (originalStep: InstructionStep) => void;
  variables: Variable[];
  routineRunStep: InstructionStep;
}

marked.use({
  gfm: true,
  breaks: true,
});

export const InstructionCell: React.FC<InstructionCellProps> = ({
  step,
  updateStep,
  deleteStep,
  variables,
  routineRunStep,
}) => {
  const [localStep, setLocalStep] = useState<InstructionStep>(step);
  const textareaRef = useRef<HTMLTextAreaElement>(null);

  useEffect(() => {
    setLocalStep(step);
  }, [step, variables]);

  useEffect(() => {
    if (localStep.mode === ModeEnum.EDITING) {
      if (textareaRef.current) {
        textareaRef.current.focus();
        autoGrow(textareaRef.current);
      }
    }
  }, [localStep.mode, localStep.step_id]);

  const handleKeyDown = (event: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (event.key === "Enter" && (event.ctrlKey || event.metaKey)) {
      doneEdit();
    } else if (event.key === "Escape") {
      cancelEdit();
    }
  };

  const handleKeyUp = (event: React.KeyboardEvent<HTMLTextAreaElement>) => {
    autoGrow(event.currentTarget);
  };

  const replaceWithVariables = (content: string) => {
    for (const v of variables) {
      let toReplaceWith = "";
      if (content.includes(v.name) && v.value) {
        toReplaceWith = `<span style="color: black; font-weight: bold;">${String(
          v.value
        ).trim()}</span>`;
      } else {
        toReplaceWith = `<span style="color: red; font-weight: bold;">[${v.name.trim()} not set]</span>`;
      }
      content = content.replace(new RegExp(`{${v.name}}`, "g"), toReplaceWith);
    }
    return content;
  };

  const renderMarkdown = (content: string) => {
    const contentWithVariables = replaceWithVariables(content);
    return { __html: marked(contentWithVariables) };
  };

  const enableEditMode = (event: React.MouseEvent<HTMLSpanElement>) => {
    const element = event.target as HTMLElement;
    if (element.tagName === "A") {
      event.preventDefault();
      return;
    }
    setLocalStep({ ...localStep, mode: ModeEnum.EDITING });
  };

  const doneEdit = () => {
    updateStep(localStep);
  };

  const doDelete = () => {
    deleteStep(step);
  };

  const cancelEdit = () => {
    if (localStep.instruction.trim() === "") {
      deleteStep(step);
    } else {
      setLocalStep({ ...step, mode: ModeEnum.OUTPUT });
    }
  };

  const autoGrow = (element: HTMLTextAreaElement) => {
    if (element && element.scrollHeight > element.clientHeight) {
      element.style.height = `${element.scrollHeight}px`;
      element.style.width = "100%";
    }
  };
  const renderContent = () => {
    if (localStep.mode === ModeEnum.EDITING) {
      return (
        <div className="w-full">
          <div className="items-top gap-4">
            <div
              onClick={enableEditMode}
              className="flex-col items-center p-3 bg-gray-100 cursor-pointer border"
            >
              <Textarea
                ref={textareaRef}
                value={localStep.instruction}
                onChange={(e) =>
                  setLocalStep({
                    ...localStep,
                    instruction: (e.currentTarget as HTMLTextAreaElement).value,
                  })
                }
                placeholder="Enter your AI instructions and hit the play button to generate your output."
                className="w-full focus:ring-slate-800 focus:border-slate-800 bg-white"
                onKeyDown={handleKeyDown}
                onKeyUp={handleKeyUp}
              />
            </div>
          </div>
          <div className="flex space-x-2 align-middle justify-end mt-2 mb-4">
            <Button onClick={doneEdit} className="px-2 py-1">
              <CheckIcon className="w-4 h-4" />
            </Button>
            <Button className="px-2 py-1">
              <TrashIcon onClick={doDelete} className="w-4 h-4" />
            </Button>
            <Button
              variant="secondary"
              onClick={cancelEdit}
              className="px-2 py-1"
            >
              <XIcon className="w-4 h-4" />
            </Button>
          </div>
          {localStep.error && (
            <div className="text-xs text-red-500">{localStep.error}</div>
          )}
        </div>
      );
    } else if (
      (routineRunStep?.mode || localStep.mode) === ModeEnum.PROCESSING
    ) {
      return (
        <div className="w-full">
          <span className="flex items-top p-3 bg-gray-100 border">
            <SparklesIcon className="min-w-4 min-h-4 max-w-4 max-h-4 mr-2 mt-1 text-hero-600" />
            <div
              dangerouslySetInnerHTML={renderMarkdown(
                routineRunStep?.instruction || localStep.instruction
              )}
            ></div>
          </span>
          <div className="flex items-top px-3 my-4 flex-col">
            <div className="animate-pulse mt-2 space-y-4">
              <div className="h-4 bg-gray-300 rounded w-full"></div>
              <div className="h-4 bg-gray-300 rounded w-3/4"></div>
            </div>
          </div>
        </div>
      );
    } else if (
      (routineRunStep?.mode || localStep.mode) === ModeEnum.IMPROVE ||
      (routineRunStep?.mode || localStep.mode) === ModeEnum.IMPROVING
    ) {
      return (
        <div className="w-full">
          <span className="flex items-top p-3 bg-gray-100 border">
            <BoltIcon className="min-w-4 h-4 mx-2 mt-1 text-hero-600" />
            <div className="flex">
              Working on your feedback `
              {routineRunStep?.feedback || localStep.feedback}` for the
              instruction: `
              {routineRunStep?.instruction || localStep.instruction}`
            </div>
          </span>
          <div className="animate-pulse pt-2 my-2 space-y-4">
            <div className="h-4 bg-gray-300 rounded w-full"></div>
            <div className="h-4 bg-gray-300 rounded w-3/4"></div>
          </div>
        </div>
      );
    } else {
      // OUTPUT mode (default)
      return (
        <div className="w-full">
          <div className="items-top gap-4">
            <div
              onClick={enableEditMode}
              className="flex items-top p-3 bg-gray-100 cursor-pointer border col-span-5"
            >
              <SparklesIcon className="min-w-4 min-h-4 max-w-4 max-h-4 mr-2 mt-1 text-slate-800" />
              <div
                dangerouslySetInnerHTML={renderMarkdown(
                  routineRunStep?.instruction || localStep.instruction
                )}
                className="flex-grow"
              />
            </div>
          </div>
          {!(routineRunStep?.mute || localStep.mute) &&
            (routineRunStep?.message || localStep.message || "") && (
              <div className="p-2 ml-2 flex items-top gap-4 text-muted-foreground">
                {routineRunStep?.error ? (
                  <XIcon className="mt-2 min-w-3 min-h-3 max-h-3 max-w-3 text-red-600"></XIcon>
                ) : (
                  <CheckIcon className="mt-2 min-w-3 min-h-3 max-h-3 max-w-3 text-green-600"></CheckIcon>
                )}
                <div
                  dangerouslySetInnerHTML={renderMarkdown(
                    routineRunStep?.message || localStep.message || ""
                  )}
                  className="flex-grow"
                />
              </div>
            )}
          {routineRunStep?.error ||
            (localStep.error && (
              <div className="text-xs text-red-500">
                {routineRunStep?.error || localStep.error}
              </div>
            ))}
        </div>
      );
    }
  };

  return <div className="step px-2">{renderContent()}</div>;
};
